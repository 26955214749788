import React, { useEffect, useState, useCallback } from 'react';
import { formatDistance } from 'date-fns';

export type TimestampProps = {
  date: Date;
};

export function Timestamp({ date }: TimestampProps): React.JSX.Element {
  const getDateString = useCallback((d: Date): string => formatDistance(d, new Date(), { includeSeconds: true, addSuffix: true }), []);

  const [dateString, setDateString] = useState<string>(getDateString(date));

  useEffect(() => {
    const id = setInterval(() => {
      setDateString(getDateString(date));
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [date, getDateString]);

  return <span className="rounded bg-slate-600 px-[0.125rem]">{dateString || 'unknown'}</span>;
}
